/**
 * Created by osirvent on 27/01/2017.
 */
angular.module('annexaApp')
    .factory('ABMModelFactory', ['$rootScope', 'apiAdmin', 'AdminFactory', '$translate', 'Language', 'CommonService', 'GlobalDataFactory', function($rootScope, apiAdmin, AdminFactory, $translate, Language, CommonService, GlobalDataFactory) {
        var factory = {};

        factory.scope = undefined;
        factory.transactionProcedureProposalTypes = [];
        factory.AT_GUIDED = 'GUIDED';
        factory.AT_NONGUIDED = 'NONGUIDED';
        factory.AT_INHERITED = 'INHERITED';
        factory.TT_NORMAL = 'NORMAL';
        factory.TT_FINAL = 'FINAL';

        //region General

        factory.setScope = function (scope) {
            factory.scope = scope;
        };

        factory.getOptions = function() {
        	
        	var rolesInterested = [];
        	var usersList = angular.copy(AdminFactory.usersList);
        	
        	_.forEach(AdminFactory.rolesInterested, function(role){
        		if(role.canUseDossier){
        			rolesInterested.push(role);
        		}
        	});
        	
        	if(rolesInterested.length > 0) {
        		rolesInterested = $linq(rolesInterested).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
        	}
        	
            if(usersList && usersList.length > 0) {
                usersList = $linq(usersList).select("x => x.user").toArray();
                usersList.unshift({id: -1, completeName: 'global.literals.empty'});
            }
        	
            return {
                showSubject: apiAdmin.showSubjectList,
                expireType: apiAdmin.expirationTypeList,
                family: GlobalDataFactory.familyProceduresTree,
				councillor: GlobalDataFactory.councillorsTree,
                archiveClassification: GlobalDataFactory.archiveClassificationsTree,
                showClassification: apiAdmin.showClassificationList,
                procedureStartProfiles: AdminFactory.profiles,
                procedureTramitationProfiles: AdminFactory.profiles,
                procedureViewProfiles: AdminFactory.profiles,
                procedureResponsibleProfiles: AdminFactory.profiles,
                procedureRoleInterested: rolesInterested,
                procedureFooterClaims: GlobalDataFactory.footerClaims,
                allowThirds: apiAdmin.yesNo,
                allowExpedientRelations: apiAdmin.yesNo,
                allowUserChangeMaxDays: apiAdmin.yesNo,
                daysComputeGlobal: apiAdmin.yesNo,
                showEndProcess: apiAdmin.yesNo,
                userInitialStateInheritance: apiAdmin.yesNo,
                processProfiles: AdminFactory.profiles,
                assignationType: [ { id: factory.AT_GUIDED, description: 'global.literals.typeGuided' }, { id: factory.AT_NONGUIDED, description: 'global.literals.typeNonGuided' } ],
                assignationTypeTram:[{ id: factory.AT_GUIDED, description: 'global.literals.typeTramGUIDED' }, { id: factory.AT_INHERITED, description: 'global.literals.typeTramINHERITED' }, { id: factory.AT_NONGUIDED, description: 'global.literals.typeTramNONGUIDED' }],
                tramitationType: [ { id: factory.TT_NORMAL, description: 'global.literals.no' }, { id: factory.TT_FINAL, description: 'global.literals.yes' } ],
                transactionType:AdminFactory.transactionTypesOrdinary,
                phase:GlobalDataFactory.tramitationPhase,
                active: apiAdmin.yesNo,
                userStateInheritance:[],
                procedureType: [
                    { id: 'PROCEDURE', description: 'global.literals.PROCEDURE' },
                    { id: 'SUBPROCEDURE', description: 'global.literals.SUBPROCEDURE' },
                    { id: 'PROCEDURE_SUBPROCEDURE', description: 'global.literals.PROCEDURE_SUBPROCEDURE' }
                ],
                subprocedure: AdminFactory.subprocedures,
                accessLevelNotification: [
                    {id:null, description:'-'},
                    {id:'HIGH', description:'global.literals.HIGH'},
                    {id:'MEDIUM', description:'global.literals.MEDIUM'},
                    {id:'LOW', description:'global.literals.LOW'}
                ],
                deleteInitialNode: apiAdmin.yesNo,
                deleteFinalNode: apiAdmin.yesNo,
                operationsActive: apiAdmin.yesNo,
                operationTypes: GlobalDataFactory.operationTypes,
                inspectionIntervention: apiAdmin.yesNoBooleanNull,
                inspectionInterventionType: apiAdmin.inspectionInterventionTypes,
                closeExpireType: apiAdmin.expirationTypeList,
                transferExpireType: apiAdmin.expirationTypeList,
                archiveExpireType: apiAdmin.expirationTypeList,
                automaticClose: apiAdmin.yesNoBooleanNull,
                automaticTransfer: apiAdmin.yesNoBooleanNull,
                automaticArchive: apiAdmin.yesNoBooleanNull,
				allProfiles: AdminFactory.profiles,
				allowHaveTerritorialAddress: apiAdmin.yesNo,
				allowUserChangeExpirationDaysNotification: apiAdmin.yesNo,
				allowManualDossierNumbering: apiAdmin.yesNo,
				forceHaveThird: apiAdmin.yesNo,
				proposalType: GlobalDataFactory.proposalTypes,
				organsOpinion: ((GlobalDataFactory.organs)?$linq(GlobalDataFactory.organs).where("x => x.resolutionType == 'OPINION'").toArray():[]),
				organsAgreement: ((GlobalDataFactory.organs)?$linq(GlobalDataFactory.organs).where("x => x.resolutionType == 'AGREEMENT'").toArray():[]),
				organsAgreementDelegationType: ((AdminFactory.councillorProposalTypeDelegationType)?AdminFactory.councillorProposalTypeDelegationType:[]),
				notificatorProfile: ((AdminFactory.profiles)?AdminFactory.profiles:[]),
				internalProfiles: ((AdminFactory.profiles)?AdminFactory.profiles:[]),
				ammendmentTransactionType: ((GlobalDataFactory.transactiontypes)?$linq(GlobalDataFactory.transactiontypes).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[4].id).toArray():[]),
				transferTransactionType: ((GlobalDataFactory.transactiontypes)?$linq(GlobalDataFactory.transactiontypes).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[3].id).toArray():[]),
				cancelTransactionType: ((GlobalDataFactory.transactiontypes)?$linq(GlobalDataFactory.transactiontypes).where("x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id).toArray():[]),
				transactionProcedureProposalTypes: factory.transactionProcedureProposalTypes,
				useCustomFieldProfile: apiAdmin.yesNo,
				useCustomFieldUser: apiAdmin.yesNo,
				customFieldProfile: [],
				customFieldUser: [],
				responsibleUserInternalControl: usersList
            };
        };
        factory.getOptionsAdd = function() {
            return {
                procedureClassifications: ''
            };
        };

        factory.resetModel = function () {
            factory.scope.model = factory.InitializeModel();
        };

        //endregion

        //region Procedure

        factory.InitializeModel = function() {
            return {
                language1: '',
                language2: '',
                language3: '',
                descriptionLanguage1: '',
                descriptionLanguage2: '',
                descriptionLanguage3: '',
                maxDays: 1,
                acronym: '',
                family: {},
				councillor: {},
                showSubject: 'DISABLED',
                expireType: 'BUSINESS',
                showClassification: 'DISABLED',
                active: 'NO',
                guided: true,
                procedureViewProfiles: [],
                procedureRoleInterested: [],
                procedureFooterClaims: [],
                procedureStartProfiles: [],
                procedureTramitationProfiles: [],
                procedureClassifications: [],
                transactions: [],
				transactionTransitionGuideds: [],
                informationLanguage1: '',
                informationLanguage2: '',
                informationLanguage3: '',
                archiveClassification: {},
                graphJSON: '',
                procedureType: 'PROCEDURE',
                procedureResponsibleProfiles: [],
                allowUserChangeMaxDays: 'NO',
                allowManualDossierNumbering: 'NO',
                tramitationTypeProcedure: $rootScope.app.configuration.default_tramitation_type.value,
                accessLevelNotification: null,
                operationsActive:'NO',
                operationTypes: [],
                inspectionIntervention: undefined,
                inspectionInterventionType: undefined,
                accessLevelNotification: null,
                expirationDaysNotification: $rootScope.app.configuration.notification_expiration_days.value,
                allowUserChangeExpirationDaysNotification: 'YES',
                allowHaveTerritorialAddress: 'YES',
                forceHaveThird: 'NO',
                daysBeforeAlertDossier: undefined,
                daysBeforeAlertTransaction: undefined,
                closeExpireType: undefined,
                transferExpireType: undefined,
                archiveExpireType: undefined,
                automaticClose: undefined,
                automaticTransfer: undefined,
                automaticArchive: undefined,
				emgdeSecurityLevel:undefined,
				emgdeAccesType:undefined,
				emgdeLimitCause:undefined,
			    emgdeLegalLimitCause:undefined,
				emgdeReuseCondition:undefined,
				emgdeValueType:undefined,
				emgdeRatingTerms:undefined,
				emgdeSecundaryValue:undefined,
				emgdeOpinionType:undefined,
				emgdeOpinionAction:undefined,
				emgdeOpinionActionTerms:undefined,
				emgdeTransferArchive:undefined,
				emgdeTransferTerms:undefined,
				emgdeEssentialDocument:undefined,
				customFields:[],
				documentationToProvide: [],
				secretaryNotificationConfiguration:{},
				procedureProposalTypes:[],
				proposalType: undefined,
				organsOpinion: [],
				organsAgreement: undefined,
				organsAgreementDelegationType: undefined,
				proposalTitle: undefined,
				notificatorProfile: undefined,
				internalProfiles: [],
				ammendmentTransactionType: undefined,
				transferTransactionType: undefined,
				cancelTransactionType: undefined,
				spelInspectionIntervention: undefined,
				responsibleUserInternalControl: undefined,
				predefinedRecords: undefined
            };
        };

        //endregion

        //region Transaction

        factory.getAssignationType = function (cell) {
            if(factory.scope.model && factory.scope.model.transactions && factory.scope.model.transactions[cell.id]) {
                return factory.scope.model.transactions[cell.id].assignationType;
            } else {
                if(cell.get('type') == 'event-sub-process') {
                    return factory.AT_NONGUIDED;
                } else {
                    return factory.AT_GUIDED;
                }
            }
        };

        factory.getTramitationType = function (cell, graphSearch) {
            if(graphSearch == undefined) {
                graphSearch = false;
            }

            if(graphSearch) {
                if (cell.attributes.attrs['.outer']['stroke-width'] == 'none' || cell.attributes.attrs['.outer']['stroke-width'] == 1) {
                    return factory.TT_NORMAL;
                } else {
                    return factory.TT_FINAL;
                }
            } else {
                if (factory.scope.model && factory.scope.model.transactions && factory.scope.model.transactions[cell.id]) {
                    return factory.scope.model.transactions[cell.id].tramitationType;
                } else {
                    if (cell.attributes.attrs['.outer']['stroke-width'] == 'none' || cell.attributes.attrs['.outer']['stroke-width'] == 1) {
                        return factory.TT_NORMAL;
                    } else {
                        return factory.TT_FINAL;
                    }
                }
            }
        };

        factory.setTransactionModel = function(cell, property, value) {
            factory.scope.model.transactions[cell.id][property] = value;
        };

        factory.getTransactionModel = function(cell, property) {
            return factory.scope.model.transactions[cell.id][property];
        };

        factory.InitializeTransaction = function(noGudied, cell, shapeType) {
            var transaction = {
                language1: '',
                language2: '',
                language3: '',
                descriptionLanguage1: '',
                descriptionLanguage2: '',
                descriptionLanguage3: '',
                informationLanguage1: '',
                informationLanguage2: '',
                informationLanguage3: '',
                maxDays: 1,
                expireType: 'BUSINESS',
                allowThirds: 'NO',
                allowExpedientRelations: 'NO',
                daysComputeGlobal: 'YES',
                showEndProcess: 'NO',
                userInitialStateInheritance: 'NO',
                processByProfile: false,
                customFields: [],
                processProfiles: [],
                //viewProfiles: [],
                assignationType: 'GUIDED',
                tramitationType: 'NORMAL',
                phase: '',
                graphNodeId: '',
                graphNodeType: '',
                subprocedure: '',
                transactionType:'',
                deleteInitialNode: 'NO',
                deleteFinalNode:'NO',
				transactionTemplates:[],
				transactionProcedureProposalTypes:[],
				useCustomFieldProfile:'NO',
				useCustomFieldUser:'NO'
            }

            if(noGudied) {
                transaction.daysComputeGlobal= 'NO';
                transaction.type = 'NONGUIDED';
                transaction.processByProfile = true;
            }

            if(cell) {
                transaction.graphNodeId = cell.id;
                transaction.graphNodeType = shapeType;
            }

            return transaction;
        }

		factory.setTransactionTransitionModel = function(cell, property, value) {
            factory.scope.model.transactionTransitionGuideds[cell.id][property] = value;
        };

        factory.getTransactionTransitionModel = function(cell, property) {
            return factory.scope.model.transactionTransitionGuideds[cell.id][property];
        };

		factory.removeTransactionTransitionModel = function(cell) {
			if(factory.scope.model.transactionTransitionGuideds){
				var tts = [];
				_.forOwn(factory.scope.model.transactionTransitionGuideds, function(value, key){
					tts.push(angular.copy(value));
                }); 
				factory.scope.model.transactionTransitionGuideds = {};
				_.forEach(tts,function(ttsBPM){
					if(ttsBPM.graphNodeId !== cell.id){
						factory.scope.model.transactionTransitionGuideds[ttsBPM.graphNodeId] = ttsBPM;
					} 
				});
			}
        };

        factory.InitializeTransactionTransition = function(cell, shapeType) {
			var current = cell.get("source");
			current = ((current && current.id)?current.id:undefined);
			var next = cell.get("target");
            next = ((next && next.id)?next.id:undefined);
			var transactionTransition = {
		    	spel:""
            }
            
            if(cell) {
                transactionTransition.graphNodeId = cell.id;
                transactionTransition.graphNodeType = shapeType;
            }

            return transactionTransition;
        }

        factory.setStartTransaction = function(cell, shapeType) {
            var gatewayId = CommonService.guid();
            cell.set('gatewayId', gatewayId);
            cell.set('gatewayPath', { gatewayId: gatewayId, path: 1 });
            factory.scope.model.transactions[cell.id] = factory.InitializeTransaction(false, cell, shapeType);
            factory.setTransactionModel(cell, 'tramitationType', 'INITIAL');

            Language.getAllTranslations('global.bpm.event.start')
                .then(function(data) {
                    factory.setTransactionModel(cell, 'language1', data.language1);
                    factory.setTransactionModel(cell, 'language2', data.language2);
                    factory.setTransactionModel(cell, 'language3', data.language3);
                }).catch(function(data) {
                    factory.setTransactionModel(cell, 'language1', data.language1);
                    factory.setTransactionModel(cell, 'language2', data.language2);
                    factory.setTransactionModel(cell, 'language3', data.language3);
            });
        }

        factory.sanitizeModel = function(){
            if(factory.scope.model.transactions){
                var trans = factory.scope.model.transactions;
                _.forOwn(trans, function(value, key) {
                    if(!_.contains(['SubprocessNormal', 'SubprocessFreeNormal'], value.graphNodeType)) {
                        if (!value.hasOwnProperty('language1')) {
                            delete factory.scope.model.transactions[key];
                        }
                    }else if(_.contains(['SubprocessFreeNormal'], value.graphNodeType)) {
                        if (!value.hasOwnProperty('subprocedureName1')) {
                            delete factory.scope.model.transactions[key];
                        }
                    } else {
                        if (!value.hasOwnProperty('subprocedure')) {
                            delete factory.scope.model.transactions[key];
                        }
                    }
                });
            }
        }
        //endregion

        return factory;
    }]);